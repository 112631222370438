import CrudAppService from '../framework/api/CrudAppService';
import {
  CompareSystemUnitDto,
  CreateOrUpdateCompareSystemUnitDto,
  GetListCompareSystemUnitDto
} from '@/framework/models/compareSystemUnit';
import {Guid} from 'guid-typescript';

import request from '../utils/request';

export default class SystemUnitApi extends CrudAppService<CompareSystemUnitDto, CompareSystemUnitDto, Guid, GetListCompareSystemUnitDto, CreateOrUpdateCompareSystemUnitDto, CreateOrUpdateCompareSystemUnitDto> {
  constructor() {
    super('/api/compareSystemUnit');
  }

  public async getUnits(): Promise<any> {
    return new Promise((resolve, reject) => {
      request({
        url: `${this.url}/units`,
        method: 'get'
      }).then((res) => resolve(res.data)).catch((error) => reject(error));
    });
  }
}
