











































import {Vue, Component, Prop, Watch} from 'vue-property-decorator';
import '@/plugins/vxetable';
import {Table} from 'vxe-table';
import SystemUnitApi from '@/api/SystemUnitApi';
import {CompareSystemUnitDto, GetListCompareSystemUnitDto} from '@/framework/models/compareSystemUnit';
import {PageResultDto} from '@/framework/dto/ResultDto';
import {Guid} from 'guid-typescript';

@Component({})
export default class SelectUnit extends Vue {
  @Prop({required: true, default: () => false}) private visible!: boolean;
  @Prop({required: true}) private title!: string;
  @Prop({required: true}) private sysId!: Guid;

  private api = new SystemUnitApi();
  private listQuery = new GetListCompareSystemUnitDto();
  private result: PageResultDto<CompareSystemUnitDto> = {
    items: new Array<CompareSystemUnitDto>(),
    totalCount: 0
  };
  private rules = {
    unitId: [
      {required: true, message: '请选择设备'},
    ],
    orderNum: [
      {pattern: '^[0-9]{0,3}$', message: '格式不正确'},
    ]
  };

  private unitList = [];

  @Watch('sysId')
  public getSystemId(v: Guid) {
    this.listQuery.systemId = v;
  }

  public handleCreate() {
    this.insertEvent();
  }

  public async insertEvent(row?: any) {
    const $table = this.$refs.dataTable as Table;
    const record = {
      isShow: false,
      orderNum: 0,
      compareSystemId: this.sysId
    };
    const insertRecords = $table.getInsertRecords();
    if (insertRecords.length <= 0) {
      const {
        row: newRow
      } = await $table.insertAt(record, row);
      await $table.setActiveRow(newRow);
    }
  }

  public async saveEvent(row: any) {
    const $table = this.$refs.dataTable as Table;
    const errMap = await $table.validate().catch((error) => error);
    if (!errMap) {
      $table.clearActived().then(() => {
        if ($table.isUpdateByRow(row)) {
          this.edited(row);
        } else if (!row.id) {
          this.creating(row);
        }
      });
    }
  }

  public async handlerDelete(row: any) {
    this.$confirm('此操作将永久删除该设备数据，是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'error'
    }).then(async () => {
      const $table = this.$refs.dataTable as Table;
      await $table.remove(row);
      if (row.id) {
        await this.api.DeleteAsync(row.id);
        await this.list();
        this.$notify({
          message: '删除成功',
          type: 'success',
          title: ''
        });
      }
    }).catch(() => {
    });
  }

  private async creating(row: any) {
    await this.api.CreateAsync(row);
    await this.list();
    this.$notify({
      message: '添加成功',
      type: 'success',
      title: ''
    });
  }

  private async edited(row: any) {
    await this.api.UpdateAsync(row.id, row);
    await this.list();
    this.$notify({
      message: '编辑成功',
      type: 'success',
      title: ''
    });
  }

  private async mounted() {
    await this.list();
    await this.getUnitList();
  }

  private async list() {
    this.listQuery.systemId = this.sysId;
    this.result = await this.api.getListAsync(this.listQuery);
  }

  private async getUnitList() {
    const result = await this.api.getUnits();
    this.unitList = result.items;
  }

  private cancelRowEvent(row: any) {
    const $table = this.$refs.dataTable as Table;
    $table.clearActived().then(() => {
      // 还原行数据
      $table.revertData(row);
      // TODO 如果是新添加的行，需要删除该行
      if (!row.id) {
        $table.remove(row);
      }
    });
  }

  private updateHandle(row: any) {
    const $table = this.$refs.dataTable as Table;
    $table.setActiveRow(row);
  }

  private handlerBeforeClose() {
    this.$emit('beforeClose');
  }
}
