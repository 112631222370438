


































import {Vue, Component} from 'vue-property-decorator';
import '@/plugins/vxetable';
import {Table} from 'vxe-table';
import SystemApi from '@/api/SystemApi';
import {CompareSystemDto, GetListCompareSystemDto} from '@/framework/models/compareSystem';
import {PageResultDto} from '@/framework/dto/ResultDto';

import SelectUnit from './components/unit-select.vue';

@Component({
  components: {
    SelectUnit
  }
})
export default class System extends Vue {
  private api = new SystemApi();
  private listQuery = new GetListCompareSystemDto();
  private result: PageResultDto<CompareSystemDto> = {
    items: new Array<CompareSystemDto>(),
    totalCount: 0
  };
  private visible = false;
  private title = '';
  private sysId = '';

  private rules = {
    name: [
      {required: true, message: '请输入对比名称'},
    ]
  };

  get isLogin() {
    return this.$store.getters.isLogin;
  }

  public async mounted() {
    await this.list();
  }

  public handleCreate() {
    this.insertEvent();
  }

  public async insertEvent(row?: any) {
    const $table = this.$refs.dataTable as Table;
    const record = {};
    const insertRecords = $table.getInsertRecords();
    if (insertRecords.length <= 0) {
      const {
        row: newRow
      } = await $table.insertAt(record, row);
      await $table.setActiveRow(newRow);
    }
  }

  public async saveEvent(row: any) {
    const $table = this.$refs.dataTable as Table;
    const errMap = await $table.validate().catch((error) => error);
    if (!errMap) {
      $table.clearActived().then(() => {
        if ($table.isUpdateByRow(row)) {
          this.edited(row);
        } else if (!row.id) {
          this.creating(row);
        }
      });
    }
  }

  public async handlerDelete(row: any) {
    this.$confirm('此操作将永久删除该对比数据，是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'error'
    }).then(async () => {
      const $table = this.$refs.dataTable as Table;
      await $table.remove(row);
      if (row.id) {
        await this.api.DeleteAsync(row.id);
        await this.list();
        this.$notify({
          message: '删除成功',
          type: 'success',
          title: ''
        });
      }
    }).catch(() => {
    });
  }

  public handlerBeforeClose() {
    this.visible = false;
  }

  private async creating(row: any) {
    await this.api.CreateAsync(row);
    await this.list();
    this.$notify({
      message: '添加成功',
      type: 'success',
      title: ''
    });
  }

  private async edited(row: any) {
    await this.api.UpdateAsync(row.id, row);
    await this.list();
    this.$notify({
      message: '编辑成功',
      type: 'success',
      title: ''
    });
  }

  private async list() {
    this.result = await this.api.getListAsync(this.listQuery);
  }

  private cancelRowEvent(row: any) {
    const $table = this.$refs.dataTable as Table;
    $table.clearActived().then(() => {
      // 还原行数据
      $table.revertData(row);
      // TODO 如果是新添加的行，需要删除该行
      if (!row.id) {
        $table.remove(row);
      }
    });
  }

  private updateHandle(row: any) {
    const $table = this.$refs.dataTable as Table;
    $table.setActiveRow(row);
  }

  private handlerUnitList(row: any) {
    this.visible = true;
    this.title = row.name;
    this.sysId = row.id;
  }
}
