import {ExtensibleEntityDto} from '../dto/ExtensibleEntityDto';
import {PagedAndSortedResultRequestDto} from '../dto/PagedAndSortedResultRequestDto';
import {Guid} from 'guid-typescript';

export interface CompareSystemUnitDto extends ExtensibleEntityDto<Guid> {
  name: string;
  unitId: number;
  remark: string;
  orderNum: number;
  isShow: boolean;
  compareSystemId: Guid;
}

export class CreateOrUpdateCompareSystemUnitDto {
  public id: Guid = Guid.createEmpty();
  public name: string = '';
  public unitId: number | undefined;
  public remark: string = '';
  public orderNum: number | undefined;
  public isShow: boolean = false;
  public compareSystemId: Guid | undefined;
}

export class GetListCompareSystemUnitDto extends PagedAndSortedResultRequestDto {
  public filter: string = '';
  public systemId: Guid | undefined;
}
